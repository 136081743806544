import React from 'react'
import anamol from '../assets/images/anamol.jpg'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { AiFillInstagram, AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { TypeAnimation } from 'react-type-animation';
import { Link } from "react-scroll"

const Home = () => {
    return (
        <div name="home" id="home" className='pt-16 min-h-screen md:h-screen w-full bg-[#0a192f] md:pb-2'>
            <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-8 gap-10 md:flex-row-reverse'>

                <div className='pt-16 sm:pt-0'>
                    <img src={anamol} alt='anamol' className='rounded-full w-50 h-50 md:w-80 md:h-80' />
                </div>

                <div className='flex flex-col justify-center h-full'>
                    <h2 className='text-4xl py-2 md:text-3xl text-gray-300'>
                        Hi, I'm <span className='text-purple-400'>Anamol Sapkota</span>
                    </h2>
                    <h3 className="text-md py-2 md:text-xl text-gray-300">
                        <TypeAnimation
                            sequence={[
                                'System/Network Administrator',
                                1000,
                                'Tech Enthusiast',
                                1000,
                                'Web Developer',
                                1000,
                                'Network/Cyber Security Enthusiast',
                                1000
                            ]}
                            wrapper="span"
                            speed={50}
                            style={{ display: 'inline-block' }}
                            repeat={Infinity}
                        /></h3>
                    <p className="text-justify text-md py-5 leading-8 max-w-xl mx-auto md:text-lg text-gray-300">Anamol Sapkota (Nepali: अनमोल सापकोटा) is a Web Developer, Tech Enthusiast, and Network/Cyber Security Enthusiast based in Central Nepal. He currently works as a System/Network Administrator at the Information System Management Section (ISMS), Kathmandu University.</p>
                    <div className='flex justify-between'>
                        <Link to="portfolio" smooth duration={500} className="group text-lg bg-gradient-to-r from-purple-600 to-purple-950 text-white px-4 py-2 rounded-md hover:bg-gradient-to-r hover:from-purple-800 hover:to-purple-600 my-6 flex items-center cursor-pointer">
                            Portfolio
                            <span className='group-hover:rotate-90 duration-300'><MdOutlineKeyboardArrowRight className="ml-2" /></span>
                        </Link>
                        <div className="text-5xl flex place-content-end gap-5 py-2 text-gray-300">
                            <a href="https://github.com/anamolsapkota" ><AiFillGithub className="text-2xl" /></a>
                            <a href="https://np.linkedin.com/in/anamolsapkota" ><AiFillLinkedin className="text-2xl" /></a>
                            <a href="https://instagram.com/anamolsapkota" ><AiFillInstagram className="text-2xl" /></a>
                        </div>

                    </div>
                </div>



            </div>
        </div>
    )
}

export default Home
