import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

const SocialLinks = () => {
    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin className="text-2xl" />
                </>
            ),
            href: "https://www.linkedin.com/in/anamolsapkota",
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                    GitHub <FaGithub className="text-2xl" />
                </>
            ),
            href: "https://github.com/anamolsapkota",
        },
        {
            id: 3,
            child: (
                <>
                    Mail <HiOutlineMail className="text-2xl" />
                </>
            ),
            href: "mailto:info@sapkotaanamol.com",
        },
        {
            id: 4,
            child: (
                <>
                    Resume <BsFillPersonLinesFill className="text-2xl" />
                </>
            ),
            href: "/resume.pdf",
            style: 'rounded-br-md',
            download: true
        },
    ]
    return (
        <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
            <ul>
                {links.map(({id, child, href, style, download}) => {
                    return (
                    <li key={id} className={`flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-purple-800 ${style}`}>
                        <a href={href} className="flex justify-between items-center w-full text-white" download={download} target="_blank" rel="noreferrer">{child}</a>
                    </li>
                    )
                })}
            </ul>
        </div>
    )
}



export default SocialLinks