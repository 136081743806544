import React from 'react'
import alumni from "../assets/portfolio/alumni.png"
import chetanavillageresort from "../assets/portfolio/chetanavillageresort.jpeg"
import crrp from "../assets/portfolio/crrp.png"
import ekrasunya from "../assets/portfolio/ekrasunya.png"
import kannaacademy from "../assets/portfolio/kannaacademy.png"
import kuwu from "../assets/portfolio/kuwu.png"
import polysemy from "../assets/portfolio/polysemy.png"
import shivathapamagar from "../assets/portfolio/shivathapamagar.jpeg"

const Portfolio = () => {
    const portfolio = [
        {
            id: 1,
            src: alumni,
        },
        {
            id: 2,
            src: chetanavillageresort,
        },
        {
            id: 3,
            src: crrp,
        },
        {
            id: 4,
            src: ekrasunya,
        },
        {
            id: 5,
            src: kannaacademy,
        },
        { id: 6, src: kuwu, },
        { id: 7, src: polysemy, },
        { id: 8, src: shivathapamagar, },
    ]
    return (
        <div name="portfolio" id="portfolio" className='pt-16 pb-16 min-h-screen bg-[#0a192f] w-full text-white md:min-h-screen'>

            <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-purple-800'>Portfolio</p>
                    <p className='py-6'>Check out some of my works</p>
                </div>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0'>
                    {
                        portfolio.map(({ id, src }) => (

                            <div key={id} className='shadow-md shadow-gray-600 rounded-lg'>
                                <img src={src} className="rounded-md w-full h-[337px] object-cover duration-200 hover:scale-105" alt="" />
                                <div className='flex items-center justify-center'>
                                    <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>Demo</button>
                                    <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>Code</button>
                                </div>
                            </div>

                        ))
                    }
                </div>


            </div>

        </div>
    )
}

export default Portfolio
