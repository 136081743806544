import React from 'react'

const Contact = () => {
    return (
        <div name="contact" id="contact" className="w-full h-screen bg-[#0a192f] text-gray-300">

            <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-purple-800'>Contact</p>
                    <p className='py-6'>Submit the form below to get in touch with me</p>
                </div>

                <div className='flex'>
                    <form action="https://getform.io/f/20bfb07f-2971-48ef-908a-b712b2b30a0f" method='post' className='flex flex-col w-full md:w-1/2'>
                        <input type="text" name="name" placeholder='Enter your name' className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none' required />
                        <input type="email" name="email" placeholder='Enter your email' className='my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none' required />
                        <textarea name="message" placeholder='Enter your message' rows="10" required
                            className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none' />

                    <button className='text-md w-1/4 bg-gradient-to-r from-purple-600 to-purple-950 text-white px-4 py-2 rounded-md hover:bg-gradient-to-r hover:from-purple-800 hover:to-purple-600 my-6 flex items-center cursor-pointer hover:scale-110 duration-300'>Let's talk</button>
                    </form>
                </div>

            </div>

        </div>
    )
}

export default Contact
