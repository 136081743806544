import React from 'react'

const About = () => {
    return (
        <div name="about" id="about" className='w-full h-screen bg-[#0a192f] text-gray-300'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <div className="max-w-screen-lg w-full px-4 grid grid-cols-2 gap-8">
                    <div className='sm:text-right pb-8 pl-4'>
                        <h1 className="text-4xl font-bold inline border-b-4 border-purple-800">About</h1>
                    </div>
                    <div></div>
                </div>
                <div className="max-w-screen-lg w-full px-4 grid sm:grid-cols-2 gap-8">
                    <div className='sm:text-right text-4xl font-bold'>
                        <p>
                            Hi, I'm Anamol, nice to meet you. Please take a look around.
                        </p>
                    </div>
                    <div className='md:text-xl'>
                        <p>
                            I am a passionate and enthusiastic web developer with a strong interest in JavaScript. I am a quick learner and a team player. I am always looking for opportunities to learn and grow.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default About
