import React from 'react'
import html from "../assets/experience/html.png"
import css from "../assets/experience/css.png"
import javascript from "../assets/experience/javascript.png"
import node from "../assets/experience/node.png"
import reactimage from "../assets/experience/react.png"
import nextjs from "../assets/experience/nextjs.png"
import github from "../assets/experience/github.png"
import tailwind from "../assets/experience/tailwind.png"

const Experience = () => {
    const experience = [
        {
            id: 1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: node,
            title: 'Node.Js',
            style: 'shadow-orange-500'
        },
        {
            id: 5,
            src: reactimage,
            title: 'React.Js',
            style: 'shadow-blue-600'
        },
        {
            id: 6,
            src: nextjs,
            title: 'Next.Js',
            style: 'shadow-white'
        },
        {
            id: 7,
            src: github,
            title: 'GitHub',
            style: 'shadow-gray-400'
        },
        {
            id: 8,
            src: tailwind,
            title: 'TailWind',
            style: 'shadow-sky-400'
        }
    ]
    return (
        <div name="experience" id="experience" className="w-full h-screen bg-[#0a192f] text-gray-300">

            <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-purple-800'>Experience</p>
                    <p className='py-4'>These are the technologies I've worked with </p>
                </div>

                <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0'>
                    {
                        experience.map(({ id, src, title, style }) => (

                            <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                                <img src={src} className="w-20 mx-auto" alt="" />
                                <p className='mt-4'>{title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Experience
