import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full bg-[#0a192f] relative border-t border-purple-800">
      <div className="container mx-auto px-4 py-6 flex items-center justify-between">
        <span className="text-white mx-auto border-t-gray-400">&copy; 2023 Anamol Sapkota. All Rights Reserved.</span>
        <div className="absolute top-0 bottom-0 left-0 right-0 mix-blend-multiply"></div>
      </div>
    </footer>
  );
};

export default Footer;
