import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(null);
    const links = [
        {
            id: 1,
            text: 'home',
            url: '#home',
        },
        {
            id: 2,
            text: 'about',
            url: '#about',
        },
        {
            id: 3,
            text: 'portfolio',
            url: '#portfolio',
        },
        {
            id: 4,
            text: 'experience',
            url: '#experience',
        },
        {
            id: 5,
            text: 'contact',
            url: '#contact',
        },
    ];

    return (
        <div className="border-b border-purple-800 md:pl-20 md:pr-20 px-4 mb-12 z-50 flex justify-between items-center w-full h-20 fixed bg-[#0a192f]">
            <div>
                <h1 className="text-4xl text-white font-signature ml-2 cursor-pointer"><Link to="home" smooth duration={500}>Anamol Sapkota</Link></h1>
            </div>
            {/* Menu Items */}
            <ul className="hidden md:flex">
                {links.map((link) => {
                    const { id, text } = link;
                    return (
                        <li
                            key={id}
                            className={`px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 hover:text-white ${activeMenuItem === id ? 'text-white' : ''}`}>
                            <Link to={text} smooth duration={500} className="menu-item" onClick={() => setActiveMenuItem(id)}>
                                {text}
                            </Link>
                        </li>
                    );
                })}
            </ul>
            {/* HamBurger */}
            <div className="text-gray-500 text-2xl cursor-pointer pr-4 z-10 md:hidden" onClick={() => setNav(!nav)}>
                {nav ? <FaTimes /> : <FaBars />}
            </div>

            {/* Mobile Menu */}
            {nav && (
                <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-[#0a192f] text-gray-800 gap-10">
                    {links.map((link) => {
                        const { id, text } = link;
                        return (
                            <li key={id} className="py-4 cursor-pointer capitalize text-4xl text-gray-500 hover:scale-105">
                                <Link onClick={() => setNav(!nav)} to={text} smooth duration={500} className="menu-item">
                                    {text}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default Navbar;
